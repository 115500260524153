<template>
  <div class="main">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Main",
  data() {
    return {
      beforeUnload_time: 0,
      gap_time: 0,
      height: {
        height: "",
      },
    };
  },
  computed: {
    menuid() {
      return this.$store.state.menuactiveid;
    },
  },
  watch: {
    $route: {
      handler(newVal) {
        let routeSearch = "/home/searchResults"
        if (newVal.path === routeSearch) {
          this.$store.commit("SET_ClearKeyword", 1);
        } else {
          this.$store.commit("SET_ClearKeyword", 0);
        }
        console.log('clearKeyword------------', this.$store.state.clearKeyword);
      },
      immediate: true,
      deep: true
    }
  },
  mounted() { },
  created() {
    this.hh();
  },
  methods: {
    hh() {
      this.height.height = window.innerHeight - 160 + "px";
    },
  },
  destroyed() { },
};
</script>
<style scoped lang="scss">
.main {
  background: #ffffff;
  height: 100%;
  width: 100%;
}
</style>
