<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>
<script>
const request_base_path = require('../src/utils/base_url')
import { removeToken } from '@/utils/auth'
export default {
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  created() {
    // 文件
    sessionStorage.setItem("whether", false);
  },
  mounted() {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.addEventListener('unload', e => this.unloadHandler(e))
  },
  destroyed() {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  methods: {
    beforeunloadHandler() {
      this._beforeUnload_time = new Date().getTime();
    },
    unloadHandler(e) {
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      //判断是窗口关闭还是刷新
      if (this._gap_time <= 5) {
        //如果是登录状态，关闭窗口前，移除用户
        if (!this.showLoginButton) {
          removeToken();
          
        }
      }
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },
  beforeDestroy() {
    window.localStorage.removeItem("menuId");
  },

  // watch: {
  //   "$route": {
  //     handler(val) {
  //       if (val.path == '/' || val.path == '/login') {
  //         // 开发环境
  //         if (process.env.NODE_ENV == 'development') {
  //          // location.href = request_base_path.dev_url + 'cas-login?fromtype=3&redirect_url=' + request_base_path.dev_url + '#/sign_on'
  //         } else if (process.env.NODE_ENV == 'production') {
  //           // 生产环境
  //           location.href = request_base_path.pro_url + 'cas-login?fromtype=3&redirect_url=' + request_base_path.pro_url + '#/sign_on'
  //         }
  //       }
  //     },
  //     immediate: true
  //   }
  // }
};
</script>
<style>
html,
body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}
#app {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}
</style>
