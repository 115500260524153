<template>
  <div class="footer">
    <div class="item itemUl">
      <li>关于我们</li>
      <li>用户协议</li>
      <li>隐私政策</li>
      <li>常见问题</li>
      <li>意见反馈</li>
    </div>
    <div class="item">
      Copyright © 2013-现在 武汉有教文化传播有限公司. All rights reserved 鄂ICP备19003195号-2
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.footer {
  width: 100%;
  height: 100%;
  background: #1D1D1D;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: space-evenly;

  .itemUl {
    li {
      list-style-type: none;
      display: inline-block;
      padding: 0px 14px;
      cursor: pointer;
      height: 15px;
      line-height: 15px;
    }

    li:not(:first-child) {
      border-left: 1px solid #DCDCDC;
      box-sizing: border-box;
    }
  }

  .item {
    text-align: center;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    color: #E4E4E4;

    .foot_icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../assets/footicon.png") 0 0 no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
