/*
  请求基础路径
*/
module.exports = {
  // dev_url: 'http://192.168.2.18/', // 舒总环境
  dev_url: 'http://192.168.2.26:8088/', // 徐哲环境
  // dev_url: 'http://192.168.2.88:8082/', // 袁浩环境
  // dev_url: 'https://shijian.hzau.edu.cn/',
  // dev_url: 'https://shijian.hzau.edu.cn/yufabu/',
  // dev_url: 'http://dev.kzmooc.com:9013/',
  // dev_url: "https://teach.kzmooc.com/", // SASS

  // pro_url: 'https://shijian.hzau.edu.cn/yanshi/' // 测试环境
  // pro_url: 'http://211.69.142.133:81/' // 预发布环境
  // pro_url: 'https://shijian.hzau.edu.cn/' // 正式环境
  //  pro_url: 'https://shijian.hzau.edu.cn/yufabu/' // 预发布环境
  // pro_url: 'https://shijian.hzau.edu.cn/develop/' // 调试环境
  // pro_url: 'http://dev.kzmooc.com:9016/' // 对外测试环境
  // pro_url: 'https://shijian.hzau.edu.cn/sandbox/'
  pro_url: "https://teach.kzmooc.com/" // SASS
}
