import axios from 'axios'
import md5 from 'js-md5'
import {
  MessageBox,
  Message
} from 'element-ui'
import store from '@/store'
import Vue from 'vue'
import {
  getToken,
  removeToken,
  getIdentifierToken,
  removeIdentifierToken,
  getSchoolId
} from '@/utils/auth'
import Cookies from 'js-cookie'

/*捕获status状态码非200状态*/
const statusCodeTip = (status, msg) => {
  switch (status) {
    case 500:
      Message.error('服务器解析异常，请稍后重试');
      break;
    case 502:
      Message.error('服务器解析异常，请稍后重试');
      break;
    case 404:
      Message.error('请求的资源不存在');
      break;

    default:
      Message.error('请求失败');
  }
}

/*捕获data状态码非0状态*/
const dataCodeTip = (status, msg) => {
  switch (status) {
    case 2:
      if (msg === "参数错误") {
        Message.error('传入数据错误,或传入空项');
      } else {
        Message.error(msg);
      }
      break;
    case 402:
      Message.error("该账号已停用，请联系管理员！")
      break;
    case 401:
      Message.error('账号或密码错误，请重新输入！');
     
      break;
    case 403:
      MessageBox.confirm('系统检测到您当前尚未登录！', '信息提示', {
        confirmButtonText: '去登录',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false
      }).then(() => {
        Vue.prototype.my_router.push({
          path: "/login"
        });
      }).catch(() => {
      });
      break;
    default:
      Message.error(msg);
  }
}

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'http://256864k9u8.51vip.biz:58228/api', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // network: 0:外网; 1,内网
    console.log('config----', config);
    // do something before request is sent
    const network = localStorage.getItem("network");
    console.log('network----', network);
    // const schoolid = localStorage.getItem("schoolid") * 1;
    config.headers['network'] = network || 0;
    config.headers['fromtype'] = 3;
    config.headers['schoolid'] = getSchoolId()
    // if (store.state.identifier) {
      
    // }
    config.headers['identifier'] = getIdentifierToken()
    config.headers['Authorization'] = 'Bearer ' + getToken()
    const date = new Date()
    const c_time = Math.trunc(date.getTime() / 1000);
    let sign_time = c_time.toString();
    let app_version = 'v1.0';
    // const X_CSRF_TOKEN = Cookies.get("XSRF-TOKEN")
    // console.log("XSRF_TOKENXSRF_TOKENXSRF_TOKENXSRF_TOKENXSRF_TOKENXSRF_TOKEN", X_CSRF_TOKEN)
    config.headers['app-version'] = app_version;
    config.headers['request-time'] = c_time;
    config.headers['request-sign'] = md5(md5(sign_time) + app_version);
    // config.headers['X-CSRF-TOKEN'] = X_CSRF_TOKEN;

    // md5(md5(this.loginForm.mobile) + time)

    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (response.status === 200) {
      if (typeof (response.data.code) == "undefined") {
        return res
      } else {
        if (response.data.code === 0) {
          return res
        } else if (response.data.code == 403) {
          if (getIdentifierToken()) {
            Message({
              message: response.data.message,
              type: 'error',
              duration: 2 * 1000
            });
          }
          // 清缓存
          removeToken();
          removeIdentifierToken();
          // 执行退出
          // store.dispatch("logout");
          // 跳转链接
          Vue.prototype.my_router.push({
            path: "/login"
          });
          return res
        } else if (response.data.code == 7) {
          // Message({
          //   message: res.message,
          //   type: 'error',
          //   duration: 2 * 1000
          // })
          Vue.prototype.my_router.push({
            path: "/NotFound"
          });
        } else if (res.code == 12) {
          Message({
            message: "登录状态已失效，请重新登录！",
            type: 'error',
            duration: 2 * 1000
          })
          location.reload();
        } else {
          dataCodeTip(response.data.code, response.data.message)
          return res
        }
      }

    } else {
      statusCodeTip(response.status, response.data.message)
    }

  },
  error => {
    if (error.toString().indexOf('Error: timeout') !== -1) {
      Message({
        message: '网络请求超时',
        type: 'error',
        duration: 2 * 1000
      })
      return Promise.reject(error)
    }

    if (error.toString().indexOf('Error: Network Error') !== -1) {
      Message({
        message: '网络请求错误',
        type: 'error',
        duration: 2 * 1000
      })
      return Promise.reject(error)
    }
    // console.log('err' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 2 * 1000
    // })
    return Promise.reject(error)

  }
)
export default service